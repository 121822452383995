import { Box, Button, Flex, Show } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { ETH_NODE_OPERATORS_PAGE_CLICK } from '@/constants/analytics';
import { IIndexable } from '@/customtypes/common';
import { StrapiSectionDetail } from '@/customtypes/global';
import { trackEvent } from '@/dapp-sdk-v2';
import BaseImage from '@/dapp-sdk-v2/components/Image';
import { TextPrimary, TextSecondary } from '@/themes';

import Section from '../Section';

interface NodeOperatorsBannerProps {
  sectionDetails: StrapiSectionDetail;
  img_key: string;
}

const getNodeBanner: IIndexable = {
  eth_marketing_node: '/assets/images/eth_node/marketing_node.svg',
};

const NodeOperatorsBanner = ({
  img_key,
  sectionDetails,
}: NodeOperatorsBannerProps) => {
  const router = useRouter();

  return (
    <Section>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        background="#D6ECF6"
        borderRadius={{ base: '24px', md: ' 40px' }}
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        textAlign={{ base: 'center', md: 'left' }}
        w="100%"
        maxW="1176px"
        h={{ base: '455px', lg: '420px' }}
        p={{ base: '16px', lg: '0 70px 0 80px' }}
      >
        <Flex
          max-width="531px"
          gap="48px"
          flexDir="column"
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Box maxW="564px">
            {sectionDetails.title && (
              <TextPrimary
                fontWeight={{ base: 600, lg: 700 }}
                fontSize={{ base: '20px', md: '40px' }}
                lineHeight="120%"
                color="#142528"
              >
                {sectionDetails.title}
              </TextPrimary>
            )}
            {sectionDetails?.sub_title && (
              <TextSecondary
                mt="8px"
                fontWeight={{ base: '500', md: '400' }}
                fontSize={{ base: '12px', md: '14px' }}
                lineHeight={{ base: '18px', md: '24px' }}
                color="#3F6870"
              >
                {sectionDetails.sub_title}
              </TextSecondary>
            )}
          </Box>

          {sectionDetails.cta_link && sectionDetails.cta_text && (
            <Button
              padding="12px 45px"
              background="#07A658"
              borderRadius="8px"
              color="white"
              w={{ base: '296px', lg: '276px' }}
              h={{ base: '56px', lg: '54px' }}
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
              onClick={() => {
                router.push(sectionDetails.cta_link as string);
                trackEvent(ETH_NODE_OPERATORS_PAGE_CLICK);
              }}
            >
              {sectionDetails.cta_text}
            </Button>
          )}
        </Flex>

        <Flex h={{ base: 222, lg: 280 }} overflow="hidden" alignItems="center">
          {img_key && (
            <>
              <Show above="lg">
                <BaseImage
                  src={getNodeBanner[img_key]}
                  alt="security"
                  width={375}
                  height={280}
                />
              </Show>
              <Show below="lg">
                <BaseImage
                  src={getNodeBanner[img_key]}
                  alt="security"
                  width={295}
                  height={222}
                />
              </Show>
            </>
          )}
        </Flex>
      </Flex>
    </Section>
  );
};

export default NodeOperatorsBanner;
